import { ICustomer } from '../../model';
import { getApi } from './api';

const URL = '/customer';

const getList = async () => {
  const api = await getApi({ ignoreCustomer: true });
  return api.get<ICustomer[]>(URL).then((response) => response.data || []);
};

export const customersService = {
  getList,
};
