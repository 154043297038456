import { Action } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, concatMap, filter, mergeMap } from 'rxjs/operators';
import { customersActions } from './customers-slice';
import { customersService } from '../../../services';
import { ICustomer } from '../../../model';

const CustomerKey = 'SELECTED-CUSTOMER';

const getSavedCustomer = (customers: ICustomer[]) => {
  const customerId = localStorage.getItem(CustomerKey);
  return customers.find((x) => x.id === customerId) || null;
};

const getCustomersEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(customersActions.fetchCustomers.match),
    concatMap((_) =>
      from(customersService.getList()).pipe(
        mergeMap((x) =>
          of(customersActions.fetchCustomersSuccess(x), customersActions.setCustomer(x.length === 1 ? x[0] : getSavedCustomer(x))),
        ),
        catchError((err) => of(customersActions.fetchCustomersFailed(err.message))),
      ),
    ),
  );

const setCustomerEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(customersActions.setCustomer.match),
    mergeMap((action) => {
      if (action.payload) {
        localStorage.setItem(CustomerKey, action.payload.id);
      } else {
        localStorage.removeItem(CustomerKey);
      }
      return of();
    }),
  );

export const customersEpic = combineEpics<Action>(getCustomersEpic, setCustomerEpic);
