export class UserProfile {
  readonly username: string;
  readonly name: string;
  readonly roles: string[];

  constructor(username: string, name: string, roles: string[]) {
    this.username = username;
    this.name = name;
    this.roles = roles || [];
  }
}
