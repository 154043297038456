import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { AppBar, AppBarSection, AppBarSpacer } from '@progress/kendo-react-layout';
import { DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';
import { logoutIcon } from '@progress/kendo-svg-icons';

import { selectUserProfile } from '../../../features/common/auth/auth-slice';
import { useExtendedLocalization } from '../../../hooks/use-extended-localization-service';
import { logout, selectCustomers } from '../../../features';
import { useAppSelector } from '../../../store/hooks';
import { CustomerDropdownList } from '../../common';
import logo from './logo.svg';

import styles from './header.module.scss';

const Header: FunctionComponent = () => {
  const isAuthenticated = useIsAuthenticated();
  const userProfile = useAppSelector(selectUserProfile);
  const localization = useExtendedLocalization();
  const customers = useAppSelector(selectCustomers);

  if (!isAuthenticated) return null;

  const theme = 'dark';

  const onItemClick = (text: string) => {
    switch (text) {
      case 'Logout': {
        logout();
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <header className={styles.header}>
      <AppBar className={styles.appBar} themeColor={theme}>
        <AppBarSection>
          <NavLink to='/'>
            <img className={styles.logo} src={logo} alt='logo' />
          </NavLink>
        </AppBarSection>

        <AppBarSpacer />

        {customers.allCustomers.length > 1 && (
          <AppBarSection>
            <CustomerDropdownList />
          </AppBarSection>
        )}

        <AppBarSection>
          <DropDownButton
            icon='arrow-60-down'
            buttonClass={styles.dropdownIcon}
            themeColor='light'
            fillMode='link'
            rounded='small'
            text={userProfile?.name}
            popupSettings={{ popupClass: styles.dropdownPopup }}
            onItemClick={(evt) => onItemClick(evt.item.text)}
          >
            <DropDownButtonItem text={localization.toLanguageStringF('header.menu.logout')} svgIcon={logoutIcon} />
          </DropDownButton>
        </AppBarSection>
      </AppBar>
    </header>
  );
};

export default Header;
