import { IEmbedToken, IReport } from '../../model';
import { getApi } from './api';

const URL = '/report';

const getList = async () => {
  const api = await getApi();
  return api.get<IReport[]>(URL).then((response) => response.data || []);
};

const generateToken = async (reportId: string) => {
  const api = await getApi();
  return api.post<IEmbedToken>(`${URL}/${reportId}/token`).then((response) => response.data);
};

export const reportsService = {
  getList,
  generateToken,
};
