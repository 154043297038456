import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAppSettings } from '../../../model';

import { RootState } from '../../../store';

export interface IAppSettingsState {
  loading: boolean;
  data: IAppSettings | null;
}

const initialState: IAppSettingsState = {
  loading: false,
  data: null,
};

const settingsSlice = createSlice({
  name: 'appSettings',
  initialState: initialState,
  reducers: {
    fetchSettings(state) {
      state.loading = true;
    },
    fetchSettingsSuccess(state, action: PayloadAction<IAppSettings>) {
      state.loading = false;
      state.data = action.payload;
    },
    fetchSettingsFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      console.log(action);
    },
  },
});

// Actions
export const appSettingsActions = settingsSlice.actions;

// Selectors
export const selectAppSettingsLoading = (state: RootState) => state.appSettings.loading;
export const selectAppSettings = (state: RootState) => state.appSettings.data;

// Reducer
export const appSettingsReducer = settingsSlice.reducer;
