import { IAppSettings } from '../model';

let settings: Promise<IAppSettings> | null = null;

export const getAppSettings = async (): Promise<IAppSettings> => {
  if (settings) return settings;

  settings = fetch('/settings.json').then((s) => s.json());
  return await settings;
};
