import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICustomer } from '../../../model';
import { RootState } from '../../../store';

export interface ICustomersState {
  initialized: boolean;
  current: ICustomer | null;
  allCustomers: ICustomer[];
}

const initialState: ICustomersState = {
  initialized: false,
  current: null,
  allCustomers: [],
};

const slice = createSlice({
  name: 'customers',
  initialState: initialState,
  reducers: {
    fetchCustomers(state) {
      state.initialized = false;
    },
    fetchCustomersSuccess(state, action: PayloadAction<ICustomer[]>) {
      state.allCustomers = action.payload;
      state.initialized = true;
    },
    fetchCustomersFailed(state, action: PayloadAction<string>) {
      state.initialized = true;
      console.log(action);
    },
    setCustomer(state, action: PayloadAction<ICustomer | null>) {
      state.current = action.payload;
    },
  },
});

// Actions
export const customersActions = slice.actions;

// Selectors
export const selectCustomers = (state: RootState) => state.customers;

// Reducer
export const customersReducer = slice.reducer;
