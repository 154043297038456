import { FunctionComponent } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

interface Props {
  requireAuth: boolean;
  redirectPath: string;
  children?: React.ReactElement;
}

const AuthenticatedRoute: FunctionComponent<Props> = ({ requireAuth, redirectPath, children }) => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  if (requireAuth && !isAuthenticated) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }

  if (!requireAuth && isAuthenticated) {
    const origin = location.state?.from ? location.state.from.pathname + location.state.from.search : redirectPath;
    return <Navigate to={origin} replace />;
  }

  return children ? children : <Outlet />;
};

export default AuthenticatedRoute;
