import { FC } from 'react';
import classNames from 'classnames/bind';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { customersActions, selectCustomers } from '../../../features';
import { DropDownList } from '../dropdownlist/DropDownList';

import styles from './CustomerDropdownList.module.scss';

const cx = classNames.bind(styles);

interface IProps {
  className?: string;
}

export const CustomerDropdownList: FC<IProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const customers = useAppSelector(selectCustomers);

  return (
    <DropDownList
      data={customers.allCustomers}
      dataItemKey='id'
      textField='name'
      onChange={(e) => dispatch(customersActions.setCustomer(e.target.value))}
      value={customers.current}
      placeholder='Select Customer'
      className={cx('customersDropdown', className)}
    />
  );
};
