import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export interface IGlobalizationState {
  currentLocale: string;
}

const initialState: IGlobalizationState = {
  currentLocale: 'en-US',
};

const slice = createSlice({
  name: 'globalization',
  initialState: initialState,
  reducers: {
    changeGlobalization(state, action: PayloadAction<string>) {
      state.currentLocale = action.payload;
    },
  },
});

// Actions
export const globalizationActions = slice.actions;

// Selectors
export const globalizationLocale = (state: RootState) => state.globalization.currentLocale;

// Reducer
export const globalizationReducer = slice.reducer;
