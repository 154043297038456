import React, { FC } from 'react';
import { DropDownList as DropDownListKendo, DropDownListProps } from '@progress/kendo-react-dropdowns';
import classNames from 'classnames/bind';

import styles from './DropDownList.module.scss';

const cx = classNames.bind(styles);

interface IProps extends DropDownListProps {
  placeholder?: string;
}

export const DropDownList: FC<IProps> = (props) => {
  const valueRender = (element: React.ReactElement<HTMLSpanElement>, value: unknown) =>
    value ? element : React.cloneElement(element, { ...element.props }, <span>{props.placeholder}</span>);

  return (
    <DropDownListKendo
      {...props}
      rounded={null}
      className={cx('dropdownlist', props.className)}
      valueRender={props.placeholder ? valueRender : undefined}
    />
  );
};
