import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { Outlet, RouterProvider, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { store } from './store';
import { useAppDispatch, useAppSelector } from './store/hooks';

import Layout from './components/layout/Layout';

import { appSettingsActions, selectAppSettings } from './features/common/app-settings/app-settings-slice';
import initMsalInstance from './features/common/auth/msal-instance';

import { customersActions, globalizationLocale } from './features';
import { selectUserProfile, userProfileActions } from './features/common/auth/auth-slice';
import { CustomNavigationClient } from './utils/NavigationClient';

import { IntlProvider, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import { ExtendedLocalizationService } from './features/common/globalization/extended-localization-service';
import { ExtendedLocalizationContext } from './hooks/use-extended-localization-service';

import enMessages from './assets/messages/en-US.json';
import { ConfirmationServiceProvider, ErrorBoundary } from './components/common';

import createAppRouter from './create-app-router';
import { UserProfile } from './model';

const LocalizationWrapper: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const locale = useAppSelector(globalizationLocale);
  loadMessages(enMessages, locale);

  return (
    <IntlProvider locale={locale}>
      <LocalizationProvider language={locale}>
        <ExtendedLocalizationContext.Provider value={new ExtendedLocalizationService()}>{children}</ExtendedLocalizationContext.Provider>
      </LocalizationProvider>
    </IntlProvider>
  );
};

const App = () => {
  const msalRef = useRef<IPublicClientApplication | null>(null);
  const [msal, setMsal] = useState<IPublicClientApplication | null>(null);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const settings = useAppSelector(selectAppSettings);
  const userProfile = useAppSelector(selectUserProfile);

  useEffect(() => {
    dispatch(appSettingsActions.fetchSettings());
  }, [dispatch]);

  useEffect(() => {
    if (settings && !msalRef.current) {
      const msalInstance = initMsalInstance(settings, (account) => {
        if (account) {
          const userProfile = new UserProfile(account.username, account.name || '', account.idTokenClaims?.roles || []);
          dispatch(userProfileActions.setProfile(userProfile));
        }
      });

      const navigationClient = new CustomNavigationClient(history);
      msalInstance.setNavigationClient(navigationClient);
      msalRef.current = msalInstance;
      setMsal(msalInstance);
    }
  }, [dispatch, history, settings]);

  useEffect(() => {
    if (userProfile) {
      dispatch(customersActions.fetchCustomers());
    }
  }, [dispatch, userProfile]);

  if (!settings || !msal) {
    return <div></div>;
  }

  return (
    <MsalProvider instance={msal}>
      <ConfirmationServiceProvider>
        <Layout>
          <Outlet />
        </Layout>
      </ConfirmationServiceProvider>

      <ToastContainer autoClose={2000} position='bottom-right' />
    </MsalProvider>
  );
};

const router = createAppRouter(<App />);

const AppWrapper = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <LocalizationWrapper>
          <RouterProvider router={router} />
        </LocalizationWrapper>
      </Provider>
    </ErrorBoundary>
  );
};

export default AppWrapper;
