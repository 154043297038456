import axios, { AxiosError, AxiosInstance, CancelToken, InternalAxiosRequestConfig } from 'axios';
import { Observable, Subscriber } from 'rxjs';

import { acquireToken } from '../../features/common/auth/msal-instance';
import { store } from '../../store';
import { apiErrorHandler } from './api-error-handler';
import { getAppSettings } from '../settings-service';

const getApiUrl = (): Promise<string> => {
  return getAppSettings().then((appSettings) => appSettings.api_url);
};

export function getScopes(): string[] {
  const appSettings = store.getState().appSettings.data;
  if (!appSettings) {
    return [];
  }

  return [`api://${appSettings.auth.aadClientId}/Default`];
}

const getCustomerId = (): string | undefined => {
  return store.getState().customers.current?.id;
};

interface IApiMap {
  [key: string]: AxiosInstance;
}
const _api: IApiMap = {};

interface IApiParameters {
  ignoreCustomer?: boolean | undefined;
  ignoreAllErrors?: boolean | undefined;
  ignoreErrorStatuses?: number[];
}

const getAxios = (baseUrl: string, parameters: IApiParameters | null = null): AxiosInstance => {
  const key = `${baseUrl}_${parameters ? JSON.stringify(parameters) : 'default'}`;
  if (_api[key]) {
    return _api[key];
  }

  const api: AxiosInstance = axios.create();

  api.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      config.baseURL = parameters?.ignoreCustomer ? baseUrl : `${baseUrl}/${getCustomerId()}`;

      if (config.headers && !config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
      }

      const accessToken = await acquireToken(getScopes());
      if (config.headers && accessToken) {
        config.headers['Authorization'] = 'Bearer ' + accessToken;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (err: AxiosError) => {
      console.log(err);
      if (!parameters?.ignoreAllErrors && !parameters?.ignoreErrorStatuses?.includes(err.response?.status || 0)) {
        apiErrorHandler(err);
      }

      return Promise.reject(err);
    },
  );

  _api[key] = api;
  return api;
};

export const getApi = async (parameters: IApiParameters | null = null): Promise<AxiosInstance> => {
  const apiUrl = await getApiUrl();
  return getAxios(apiUrl, parameters);
};

export function fromAxiosPromise<T>(func: (token: CancelToken) => Promise<T>): Observable<T> {
  return new Observable((observer: Subscriber<T>) => {
    const cancelToken = axios.CancelToken.source();
    func(cancelToken.token).then(
      (result) => {
        observer.next?.(result);
        observer.complete?.();
      },
      (error) => {
        if (axios.isCancel(error)) {
          observer.complete?.();
        } else {
          observer.error?.(error);
        }
      },
    );

    return () => {
      cancelToken.cancel();
    };
  });
}
