import { BrowserAuthError, ClientAuthError } from '@azure/msal-browser';
import { notifications } from '../notifications-service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const messageExtractors: Array<{ test: (err: unknown) => boolean; messages: (err: any) => string[] }> = [
  {
    test: (err) => err instanceof ClientAuthError && err.message?.startsWith('endpoints_resolution_error'),
    messages: (err) => ['Please check your network connection and try again.'],
  },
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    test: (err: any) => err.response?.data?.errors,
    messages: (err: { response: { data: { errors: { [key: string]: string } } } }) =>
      Object.keys(err.response.data.errors).map((key) => err.response.data.errors[key][0]),
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function apiErrorHandler(err: any) {
  if (!(err instanceof BrowserAuthError) && err.status !== 401 && err.code !== 'ERR_CANCELED' && err.code !== 'ECONNABORTED') {
    const messages = messageExtractors.find((e) => e.test(err))?.messages(err) || [err.message];
    messages.forEach((message) => notifications.error(message));
  }
}
