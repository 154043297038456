import { createHashRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { lazy, ReactElement } from 'react';

import AuthenticatedRoute from './components/auth/AuthenticatedRoute';
import { getAppSettings } from './services';
import { createMsalInstance, ssoSilent } from './features';

const Login = lazy(() => import('./pages/login/Login'));

const Reports = lazy(() => import('./pages/reports/Reports'));

const createAppRouter = (appElement: ReactElement) => {
  return createHashRouter(
    createRoutesFromElements(
      <Route
        element={appElement}
        handle={{
          crumb: () => 'k-i-home',
        }}
        loader={async ({ params }) =>
          getAppSettings()
            .then((settings) => createMsalInstance(settings))
            .then((msalInstance) => ssoSilent(msalInstance))
        }
      >
        <Route element={<AuthenticatedRoute requireAuth={false} redirectPath='/' />}>
          <Route path='/login' element={<Login />} />
          <Route path='*' element={<Navigate to='/login' replace />} />
        </Route>
        <Route element={<AuthenticatedRoute requireAuth={true} redirectPath='/login' />}>
          <Route index element={<Reports />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Route>
      </Route>,
    ),
  );
};

export default createAppRouter;
