import { useMsal } from '@azure/msal-react';
import { FunctionComponent, Suspense } from 'react';
import { setMsalContext } from '../../features';

import { Spinner } from '../common';
import Header from './header/Header';
import styles from './layout.module.scss';

interface Props {
  children: React.ReactNode;
}

const Layout: FunctionComponent<Props> = ({ children }) => {
  const msalContext = useMsal();
  setMsalContext(msalContext);

  return (
    <div className={styles.app}>
      <Header />
      <Suspense fallback={<Spinner />}>
        <main className={styles.main}>
          {/* <Breadcrumbs /> */}
          {children}
        </main>
      </Suspense>
    </div>
  );
};

export default Layout;
