import { combineReducers } from 'redux';

import { appSettingsReducer, customersReducer, globalizationReducer, userProfileReducer } from '../features/common';

export const rootReducer = combineReducers({
  appSettings: appSettingsReducer,
  userProfile: userProfileReducer,
  globalization: globalizationReducer,
  customers: customersReducer,
});
