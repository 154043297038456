import { PopupRequest } from '@azure/msal-browser';

export const loginRequest: PopupRequest = {
  scopes: ['openid', 'profile'],
  prompt: 'select_account',
  redirectUri: '/',
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
};

export const getSsoLoginRequest = (loginHint: string): PopupRequest => {
  return { ...loginRequest, ...{ prompt: 'none', loginHint } };
};
